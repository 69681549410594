<template>
    <Page
        ref="page"
        :title="$t('profile.change_password')"
    >
        <ChangePassword
            class="mt-8"
            @cancel="close"
        />
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import ChangePassword from '@/components/auth/ChangePassword';

export default {
    components: { ChangePassword, Page },

    methods: {
        close() {
            this.$refs.page.close();
        },
    },
};
</script>
