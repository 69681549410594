<template>
    <div class="w-full">
        <Form
            ref="form"
            :submit="changePassword"
        >
            <TextField
                name="oldPassword"
                :label="$t('auth.change_password.old_password')"
                type="password"
                :validate="required"
            />
            <TextField
                name="newPassword"
                :label="$t('auth.new_password')"
                type="password"
                :validate="required"
            />
            <div class="flex py-6">
                <button
                    type="button"
                    class="btn flex-1"
                    :disabled="loading"
                    @click="$emit('cancel')"
                >
                    {{ $t('app.cancel_action') }}
                </button>
                <button
                    type="submit"
                    class="btn btn-primary flex-1"
                    :disabled="loading"
                >
                    {{ $t('auth.change_password.change_action') }}
                </button>
            </div>
        </Form>
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { useI18n } from 'vue-i18n';
import NotifyMixin from '@/mixins/NotifyMixin';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import Loader from '@/components/ui/Loader';

export default {
    name: 'ChangePassword',
    components: {
        Form,
        TextField,
        Loader,
    },

    mixins: [NotifyMixin, ValidatorMixin],

    emits: ['cancel'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async changePassword({ oldPassword, newPassword }) {
            this.loading = true;

            try {
                const user = await Auth.currentAuthenticatedUser();

                await Auth.changePassword(user, oldPassword, newPassword);

                this.notifySuccess(this.t('auth.change_password.success'));
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
